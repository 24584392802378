import React from 'react'
import {StaticQuery, graphql} from "gatsby";

import Layout from '../components/layout'
import {Grid, Typography, Container, makeStyles} from '@material-ui/core';

import HexaBlackLogo from '../gfx/logo_BS_201202.svg';

import BinaryBackground from '../gfx/back_binary_wide_201117.svg';

import Carousel from "../components/carousel";
import Contact from "../components/contact";
import MidSiteLogo from "../../assets/logo_midsite.svg";
import MidpageLogo from "../components/midpageLogo";
import SectionContainer from "../components/sectionContainer";
import ProductBlock from "../components/productBlock";
import AboutSection from "../components/about";

const useStyles = makeStyles({
  expertiseTopGradient: {
    background: "linear-gradient(180deg, rgba(14,17,24,1) 0%, rgba(14,17,24,0) 100%)",
    width: "100%",
    height: "20vh",

    // Overcome parent padding
    position: "absolute",
    top: 0,
    left: 0
  },
  expertiseBottomGradient: {
    background: "linear-gradient(180deg, rgba(0, 59, 109, 0) 0%, rgba(0, 59, 109, 1) 100%)",
    width: "100%",
    height: "20vh",

    // Overcome parent padding
    position: "absolute",
    bottom: 0,
    left: 0
  },
});

export default () => {
  const classes = useStyles();
  return (
    <Layout>
        {/** Slider **/}
        <Carousel startPage={2} />

        {/** Logo-intermediate **/}
        <MidpageLogo/>

      {/** About **/}
      <AboutSection>
        A high performance, GPU-accelerated guaranteed linear time regular expression library that supports most
        extended regular expression features, including capture groups
      </AboutSection>

        {/** Product Descriptions - binary background */}
      <SectionContainer backgroundImage={BinaryBackground}>
        <div className={classes.expertiseTopGradient}/>
        <Grid container
              alignItems="center"
              justify="center"
              style={{
                padding: "15vh 5vw",
                position: "relative", // Enable zIndex
                zIndex: 1
              }}
        >
          <ProductBlock title="Features">
            Typically orders of magnitude faster than competing regex libraries.
            <br/>
            <br/>
            Nearly fully PCRE-equivalent, with more features being added constantly.
            <br/>
            <br/>
            To aid in correctness testing, SpecRegex can also execute on the CPU.
            <br/>
            <br/>
            NVIDIA® and AMDGPU targets are both supported.
          </ProductBlock>
          <ProductBlock title="Technology">
            Makes use of genuinely novel advancements in graph theory.
            <br/>
            <br/>
            Regexes are ahead-of-time compiled to branchless sequences of arithmetic operations, allowing non-divergent
            and high throughput parallel execution on GPUs.
            <br/>
            <br/>
            Written entirely in the C++ template engine to make use of current and future optimizations that are present
            in our other products as well, although capable of functioning independently.
          </ProductBlock>
          <ProductBlock title="Support">
            If you find something you need but that isn’t in SpecRegEx yet, we’ll simply add it on demand, ensuring
            you always have the tools you need.
            <br/>
            <br/>
            Full documentation support along with technical specifications, including comparisons to existing
            alternatives.
            <br/>
            <br/>
            Full onboarding support and training to the extent that you want and need - ensuring that you’re never
            stuck at any stage of implementation or utilisation.
          </ProductBlock>
        </Grid>
        <div className={classes.expertiseBottomGradient}/>
      </SectionContainer>
    </Layout>
  )
};
